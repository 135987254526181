//
if (document.querySelector('.home__header')){
   function fixedNav() {
      const nav = document.querySelector('.home__header')
      if (window.scrollY > 1) {
         nav.classList.add('fixed')
      } else {
         nav.classList.remove('fixed')
      }
   }
   window.addEventListener('scroll', fixedNav)
}
//
const body = document.querySelector('body');
window.addEventListener("load", ()=>{
   if (body.classList.contains('back')){
      body.classList.add('show')
   }
   if (body.classList.contains('forward')){
      body.classList.add('show')
   }
})
//
const isMobileDevice = /Mobi/i.test(window.navigator.userAgent)
if (isMobileDevice) {
   function deviceWidth() {
      const desctop = document.querySelector('.section__desctop')
      const rotate = document.querySelector('.section__rotate')
      const section = document.querySelectorAll('.section')[2]
      if (window.innerWidth <= 481) {
         function rotateScreen() {
            if (window.matchMedia("(orientation: portrait)").matches) {
               console.log("orientation: portrait")
               rotate.classList.remove('active')
               desctop.classList.remove('hide')
               section.style.display = "block"
            }
            if (window.matchMedia("(orientation: landscape)").matches) {
               console.log("orientation: landscape")
               rotate.classList.add('active')
               desctop.classList.add('hide')
               section.style.display = "none"
            }
         }
         window.addEventListener('resize', rotateScreen)
         window.addEventListener('load', rotateScreen)
      }
   }
   window.addEventListener('resize', deviceWidth)
   window.addEventListener('load', deviceWidth)
} 